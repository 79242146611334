<template>
	<div class="main-content">
		<ktv-breadcrumb
			title="Role List"
			page="Roles"
			folder="System"
		/>
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			search-placeholder="Search by Role Name"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actions>
				<ktv-button
					text="Add"
					icon="i-Add"
					@click="openForm('Add')"
				/>
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span>
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item @click="openForm('View', props.row.RoleId)">
							<img :src="iconview" class="imgicon"> View
						</b-dropdown-item>
						<b-dropdown-item @click="openForm('Update', props.row.RoleId)">
							<img :src="iconedit" class="imgicon"> Update
						</b-dropdown-item>
						<b-dropdown-item @click="deleteData(props.row.RoleId)">
							<img :src="icondelete" class="imgicon"> Delete
						</b-dropdown-item>
					</b-dropdown>
				</span>
			</template>
		</ktv-table>

		<ktv-modal
			v-model="modalsform"
			title="Form"
			size="md"
			@close="modalsform = false"
		>
			<template #content>
				<vcl-list
					v-show="loadingForm"
					class="mt-4"
				/>
				<vcl-list
					v-show="loadingForm"
					class="mt-4"
				/>
				<div v-show="!loadingForm">
					<b-form-group label="Role Name">
						<b-form-input
							v-model="form.RoleName"
							v-validate="'required'"
							name="RoleName"
							:state="validateState('RoleName')"
							data-vv-as="Role Name"
							type="text"
						/>
						<b-form-invalid-feedback>{{ veeErrors.first('RoleName') }}</b-form-invalid-feedback>
					</b-form-group>
					<b-form-group label="Description">
						<b-form-input
							v-model="form.RoleDesc"
							v-validate="'required'"
							name="RoleDesc"
							:state="validateState('RoleDesc')"
							data-vv-as="Description"
							type="text"
						/>
						<b-form-invalid-feedback>{{ veeErrors.first('RoleDesc') }}</b-form-invalid-feedback>
					</b-form-group>
					<b-form-group label="Select Group">
						<div>
							<v-multiselect-listbox
								v-model="form.RoleGroup"
								style="width: 100%"
								:options="optionGroups"
								:reduce-display-property="(option) => option.text"
								:reduce-value-property="(option) => option.value"
								no-options-found-text="No state found"
								no-selected-options-found-text="No selected state found"
							/>
						</div>
					</b-form-group>
				</div>
			</template>
		
			<template #footer>
				<div align="right">
					<ktv-button
						v-if="opsiDisplay !== 'View'"
						text="Save"
						@click="save()"
					/>
				</div>
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	import {  KtvButton, KtvTable, KtvModal } from "@/components"
	export default {
		metaInfo: {
			title: "Roles",
		},
		components: {  KtvButton, KtvTable, KtvModal },
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Role Name",
						field: "RoleName",
						thClass: "text-left",
					},
					{
						label: "Description",
						field: "RoleDesc",
						thClass: "text-left",
					},
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						RoleName: "",
					},
					sort: [
						{
							field: "RoleName",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				modalsform: false,
				form: {
					RoleId: "",
					RoleDesc: "",
					RoleGroup: []
				},
				loadingForm: false,
				optionGroups: []
			}
		},
		mounted() {
			this.loadItems()
		},
		beforeCreate() {
			this.$http
				.Get([], this.$urlApi.role.groupList)
				.then((response) => {
					if (response.success) {
						this.optionGroups = response.results
					} else {
						this.$swal("Error!", response.error_massage, "error")
					}
				})
				.catch((error) => {
					this.$swal("Error!", error.response.error_massage, "error")
				})
		},
		methods: {
			save() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}

					if (this.opsiDisplay === 'Update') {
						this.$swal({
							title: 'Are you sure?',
							text: "Data will be updated.",
							type: 'question',
							showCancelButton: true,
							confirmButtonText: 'Submit',
							showLoaderOnConfirm: true,
							preConfirm: () => {
								return this.$http.Put(this.form, this.$urlApi.role.update)
									.then((response) => { return response })
							},
							allowOutsideClick: () => !this.$swal.isLoading()
						}).then((res) => {
							if (res.value.success) {
								this.$swal('Success!', 'Data has been updated.', 'success').then(()=>{
									this.modalsform = false;
									this.loadItems();
								})
							} else {
								this.$swal('Error!', 'Data failed to be updated.', 'error')
							}
						})
					} else if (this.opsiDisplay === 'Add') {
						this.$swal({
							title: 'Are you sure?',
							text: "Data will be saved.",
							type: 'question',
							showCancelButton: true,
							confirmButtonText: 'Submit',
							showLoaderOnConfirm: true,
							preConfirm: () => {
								return this.$http.Post(this.form, this.$urlApi.role.create)
									.then((response) => { return response })
							},
							allowOutsideClick: () => !this.$swal.isLoading()
						}).then((res) => {
							if (res.value.success) {
								this.$swal('Success!', 'Data has been updated.', 'success').then(()=>{
									this.modalsform = false;
									this.loadItems();
								})
							} else {
								this.$swal('Error!', 'Data failed to be saved.', 'error')
							}
						})
					}
				});
			},
			openForm(opsi, item = null) {
				this.opsiDisplay = opsi;
				let payload = {
					roleId: item
				}
				this.modalsform = true;
				if (opsi !== 'Add') {
					this.loadingForm = true;
					this.$http
						.Get(payload, this.$urlApi.role.detail)
						.then((response) => {
							this.loadingForm = false;
							if (response.success) {
								this.form = response.results[0]
							} else {
								this.$swal("Error!", response.error_massage, "error")
							}
						})
						.catch((error) => {
							this.loadingForm = false;
							this.$swal("Error!", error.response.error_massage, "error")
						})
				}
			},
			deleteData(item) {
				let payload = {
					roleId: item,
				}
				this.$swal({
					title: 'Are you sure?',
					text: "Data will be deleted!",
					type: 'question',
					showCancelButton: true,
					confirmButtonText: 'Delete',
					showLoaderOnConfirm: true,
					preConfirm: () => {
						return this.$http.Delete(payload, this.$urlApi.role.delete)
							.then((response) => { return response })
					},
					allowOutsideClick: () => !this.$swal.isLoading()
				}).then((res) => {
					if (res.value.success) {
						this.$swal('Success!', 'Data has been deleted.', 'success')
							.then(() => { this.loadItems() })
					} else {
						this.$swal('Error!', 'Data failed to be deleted.', 'error')
					}
				})
			},
			// DATATABLES ACTIONS
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(params) {
				this.updateParams({
					columnFilters: { RoleName: params.searchTerm },
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.role.list)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.rows = response.results.data
							this.totalRecords = response.results.total
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
			// DATATABLES ACTIONS END
		},
	}
</script>
